import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useActiveData } from '../utils';
import Detail from '../components/Detail';

const List = (props) => {
  const { filterData, loading } = props;

  return (!loading && (
    <div id="itemList" className="box-container">
      <ul>
        {filterData.map((i) => (
          <li>
            <Link to={`?id=${i.id}`}>
              <img src={`/img/${i.smallImg}`} alt="coverImg" />
            </Link>
          </li>
        ))}
      </ul>
      <div className="clearfix"></div>
    </div>
  ))
}

const Works = (props) => {
  const { dataList, activeTag, getData, loading } = props;
  const activeData = useActiveData(dataList);

  useEffect(() => {
    const qs = {
      method: 'works/sel',
      tag: 0,
    };

    getData(qs);
  }, []);

  const filterData = useMemo(() => {
    let list = [...dataList];

    if (Number(activeTag) !== 0) {
      list = dataList.filter((i) => i.tagId.includes(activeTag));
    }

    return list
  }, [dataList, activeTag]);

  return (!activeData
    ? <List loading={loading} filterData={filterData} />
    : <Detail activeData={activeData} />);
}

export default Works;
