import React, { useEffect, useState } from 'react'
import animateScrollTo from 'animated-scroll-to';
import logo from '../img/nomadhouselogo2.svg';
import gotop from '../img/gotop.png';

const Footer = () => {
  const [goTopShow, setGoTopShow] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setGoTopShow(true);
    }

    if (window.scrollY <= 400) {
      setGoTopShow(false);
    }
  };

  const goTop = () => {
    animateScrollTo(0);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return (() => {
      window.removeEventListener('scroll', handleScroll);
    });
  }, [])

  return (
    <div id="footerBar">
      <div className="footer">
        <div className="footer-logo">
          <img src={logo} alt="nomadhouse" />
        </div>
        <div className="footer-list">
          <ul>
            <li><a href="mailto:nomadhouse96@gmail.com">nomadhouse96@gmail.com</a></li>
            <li>TAIWAN, Taichung</li>
          </ul>
        </div>
        <div className="clearfix"></div>
      </div>
      <div className="footer-copyright">Copyright © Nomadhouse Studio All Rights Reserved.</div>
      <div id="gotop" className={goTopShow ? 'show' : 'hide'} onClick={goTop}>
        <img src={gotop} alt="gotop" />
      </div>
    </div>
  )
};

export default Footer;