import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const request = async (method, qs) => {
  const data = JSON.stringify(qs);

  const out = await axios({
    method,
    data,
    url: '/api/'
  });

  return out.data;
};

const queryParse = (location) => {
  const search = location.search.substring(1);

  return search
    ? JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    : {};
};

const useActiveData = (list) => {
  const location = useLocation();
  const search = queryParse(location);
  const [activeData, setActiveData] = useState(null);

  useEffect(() => {
    if (list.length > 0) {
      if (search.id) {
        const target = list.find((i) => i.id == search.id);

        setActiveData(target);
        window.scrollTo(0, 0);
      }

      if (!search.id) {
        setActiveData(null);
      }
    }
  }, [search, list]);

  return activeData;
};

export {
  request,
  queryParse,
  useActiveData
};
