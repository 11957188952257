import React from 'react';

const ContentImgList = (props) => {
  const { contentImg } = props;

  return (
    <ul className="contentImg">
      {contentImg.map((img, index) => (
        <li key={index}>
          <img src={`/img/${img}`} alt="coverImg" />
        </li>
      ))}
    </ul>
  )
}

const Detail = (props) => {
  const { activeData } = props;
  const { coverImg, descr, contentImg } = activeData;

  const handleBreakLine = (content) => {
    return content.replace(/\n/g, '<br />');
  }

  return (
    <div className="work-boxcontainer">
      <ul>
        <li><img src={`/img/${coverImg}`} alt="coverImg" /></li>
      </ul>
      <div className="work-description">
        <div className="work-description-list">
          <h2 className="w-title"dangerouslySetInnerHTML={{  __html: handleBreakLine(descr[0].title) }} />
          <p dangerouslySetInnerHTML={{  __html: handleBreakLine(descr[0].content) }} />
        </div>
        <div className="work-description-info">
          <h2 className="w-title"dangerouslySetInnerHTML={{ __html: handleBreakLine(descr[1].title) }} />
          <p className="l-list-2" dangerouslySetInnerHTML={{ __html: handleBreakLine(descr[1].content) }} />
        </div>
        <div className="clearfix"></div>
      </div>
      {contentImg.length > 0 && (
        <ContentImgList contentImg={contentImg} />
      )}
    </div>
  );
};

export default Detail;
