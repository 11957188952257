import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { request } from './utils';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Loading from './components/Loading';
import Home from './views/Home';
import About from './views/About';
import Project from './views/Project';
import Works from './views/Works';
import Journal from './views/Journal';
import './css/all.css';
import './css/about.css';
import './css/journal.css';
import './css/project.css';
import './css/work-content.css';
import './css/loading.css';

function App() {
  const [dataList, setDataList] = useState([]);
  const [activeTag, setActiveTag] = useState(0);
  const [loading, setLoading] = useState(false);

  const getData = async (qs) => {
    setLoading(true);
    setDataList([]);

    const out = await request('POST', qs);

    if (out.status) {
      const newDataList = out.data.sort((a, b) => (Number(b.sort) - Number(a.sort)));

      setDataList(newDataList);
    }

    setLoading(false);
  }

  useEffect(() => {
    document.body.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

    return () => {
      document.body.removeEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
    };
  }, [])

  return (
    <Router>
      {loading && (
        <div className="c-loader-bar">
          <div className="c-loader-bar__indicator"></div>
        </div>
      )}
      <div className="wrap">
        <Nav activeTag={activeTag} setActiveTag={setActiveTag} setLoading={setLoading} />
        <div className={`container ${loading && 'loading'}`}>
          {loading && <Loading />}
          <Switch>
            <Route exact path="/">
              <Home loading={loading} dataList={dataList} activeTag={activeTag} getData={getData} />
            </Route>
            <Route path="/works">
              <Works loading={loading} dataList={dataList} activeTag={activeTag} getData={getData} />
            </Route>
            <Route path="/project">
              <Project loading={loading} dataList={dataList} getData={getData} />
            </Route>
            <Route path="/about">
              <About activeTag={activeTag} />
            </Route>
            <Route path="/journal">
              <Journal loading={loading} dataList={dataList} getData={getData} />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
