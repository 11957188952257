import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SlideDown } from 'react-slidedown';
import { request } from '../utils';
import nomadhouselogo from '../img/nomadhouselogo.svg';
import facebook from '../img/facebook.svg';
import behance from '../img/behance.svg';
import mail from '../img/mail.svg';
import 'react-slidedown/lib/slidedown.css';

const TagLink = (props) => {
  const location = useLocation();
  const path = location.pathname.replace('/', '');
  const { search } = location;
  const { tagName } = props;
  let link = tagName;

  if (path === '') {
    link = <Link to="/works">{tagName}</Link>
  }

  if (search.indexOf('id') > -1) {
    link = <Link to={`/${path}`}>{tagName}</Link>
  }
  
  return link;
}

const Nav = (props) => {
  const linkMap = ['works', 'project', 'about', 'journal'];

	const projectTags = [{
		id: 0,
		tagName: 'CONCEPT',
	}];

	const journalTags = [{
		id: 0,
		tagName: 'About Journal',
	}];

  const aboutTags = [
    {
      id: 0,
      tagName: 'Profile',
    },
    {
      id: 1,
      tagName: 'About Design',
    },
  ]

	const initTags = [{
		id: 0,
		tagName: 'All',
	}];

  const location = useLocation();
  const path = location.pathname.replace('/', '');

  const { activeTag, setActiveTag } = props;

  const [tags, setTags] = useState([]);
  const [worksTags, setWorksTags] = useState(initTags);
  const [navHide, setNavHide] = useState(false);
  const [menuShow, setMenuShow] = useState(false);

  // 取得子項目
  const getTags = async () => {
    const qs = {
      method: 'tag/sel'
    };

    const out = await request('POST', qs);

    if (out.status) {
      const newTags = initTags.concat(out.data);

      setWorksTags(newTags);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setNavHide(true);
    }

    if (window.scrollY < 200) {
      setNavHide(false);
    }
  };

  const handleMenu = () => {
    setMenuShow(!menuShow);
  };

  const hideMenu = () => {
    if (window.innerWidth > 767) {
      setMenuShow(false);
    }
  };

  const handleLink = () => {
    setMenuShow(false);
    setActiveTag(0);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    getTags();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', hideMenu);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', hideMenu);
    }
  }, [])

  useEffect(() => {
    let newTags = [...worksTags];

    if (path === 'works') {
      newTags = [...worksTags];
    }

    if (path === 'project') {
      newTags = projectTags;
    }

    if (path === 'about') {
      newTags = aboutTags;
    }

    if (path === 'journal') {
      newTags = journalTags;
    }

    setTags(newTags);
  }, [path, worksTags]);

  return (
    <SlideDown closed={navHide && !menuShow} transitionOnAppear={false}>
    <div className="header">
      <Link to="/" className="NMHlogo">
        <img src={nomadhouselogo} alt="nomadhouselogo" />
      </Link>
      <div className={`socialmedia ${menuShow && 'show'}`}>
        <ul>
          <li>
            <a href="https://www.facebook.com/nomadhouse.studio/" target="_blank" rel="noreferrer noopener" title="facebook">
              <img src={facebook} alt="facebook" />
            </a>
          </li>
          <li>
            <a href="https://www.behance.net/nomadhouse" target="_blank" rel="noreferrer noopener" title="behance">
              <img src={behance} alt="behance" />
            </a>
          </li>
          <li>
            <a href="mailto:nomadhouse96@gmail.com">
              <img src={mail} alt="mail" />
            </a>
          </li>
        </ul>
      </div>
      <div className="toggle" onClick={handleMenu}>
        <div className={`bar ${menuShow && 'open'}`}></div>
      </div>
      <div className={`topmenu ${menuShow && 'show'}`}>
        <ul>
          {linkMap.map((link) => (
            <li key={link} className="hvr-underline-from-center" onClick={handleLink}>
              <Link to={`/${link}`}>{link.toUpperCase()}</Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="clearfix"></div>
      <div id="navBar" className="menu">
        <ul>
          {tags.map((i) => (
            <li key={i.id} className={Number(activeTag) === Number(i.id) ? 'on' : ''}  onClick={() => setActiveTag(i.id)}>
              <TagLink tagName={i.tagName} />
            </li>
          ))}
        </ul>
        <div className="clearfix"></div>
      </div>
    </div>
    </SlideDown>
  );
}

export default Nav;
