import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useActiveData } from '../utils';
import Detail from '../components/Detail';

const List = (props) => {
  const { dataList, loading } = props;

  return (!loading && (
    <>
      <div id="homepageList" className="box-container">
        <ul>
          {dataList.map((i) => (
            <li>
              <Link to={`/works?id=${i.id}`}>
                <img src={`/img/${i.smallImg}`} alt="coverImg" />
              </Link>
            </li>
          ))}
        </ul>
        <div className="clearfix"></div>
      </div>
      <Link to="/works" className="viewmore">VIEW MORE</Link>
      <div className="l-info">
        <h2 className="l-title">NEWS</h2>
        <dl>
          <dt>2015.</dt>
          <dd>APD亞太設計年鑑 / 入選</dd>
        </dl>
        <dl>
          <dt>2017.</dt>
          <dd>APD亞太設計年鑑 / 入選</dd>
        </dl>
        <dl>
          <dt>2018.</dt>
          <dd>CTA亞洲創意對話Creative Talk in Asia 4 / 收錄</dd>
        </dl>
        <dl>
          <dt>2020.</dt>
          <dd>Idn 26.1 Windows Display + Visual Merchandising 櫥窗營銷設計 / 收錄</dd>
        </dl>
        <div className="clearfix"></div>
      </div>
      <div className="intro">
        <h2 className="l-title">ABOUT</h2>
        <p className="l-list">- Nomadhouse Studio -
          <br />visual and cultural activism/advocate
          <br />Nomad + house / No + mad + house /
          <br />No + madhouse / madhouse + ON
          <br />Nomadhouse is the Taiwan-based studio that creates design solutions for clients in publishing, art,
          fashion, music, lifestyle, and for cultural institutions.
          <br />
          <br />視覺行動主義者 / 提倡者。
          <br />設計該具有一定的幽默，
          <br />而幽默是深度的觀察結果，
          <br />親切地傳達給對方。
          <br />希望持續而漸進的以人味設計推動社區與環境的潜移默化。
        </p>
      </div>
    </>
  ))
};

const Home = (props) => {
  const { dataList, getData, loading } = props;
  const activeData = useActiveData(dataList);

  useEffect(() => {
    const qs = {
      method: 'works/sel',
      tag: 0,
      random: true,
      limit: 15,
    };

    getData(qs);
  }, []);

  return (activeData === null
    ? <List loading={loading} dataList={dataList} />
    : <Detail activeData={activeData} />);
};

export default Home
