import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useActiveData } from '../utils';
import Detail from '../components/Detail';

const List = (props) => {
  const { dataList, loading } = props;

  return (!loading && (
    <div id="itemList" className="box-container">
      <ul>
        {dataList.map((i) => (
          <Link className="project-holder" to={`?id=${i.id}`}>
            <img src={`/img/${i.smallImg}`} alt="smallImg"/>
            <div className="project-on-top">{i.name}</div>
          </Link>
        ))}
      </ul>
    </div>
  ))
};

const Project = (props) => {
  const { dataList, getData, loading } = props;
  const activeData = useActiveData(dataList);

  useEffect(() => {
    const qs = {
      method: 'project/sel',
      tag: 0,
    };

    getData(qs);
  }, []);

  return (activeData === null
    ? <List loading={loading} dataList={dataList} />
    : <Detail activeData={activeData} />);
}

export default Project;
