import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useActiveData } from '../utils';

const ListItem = (props) => {
  const { data } = props;
  const { smallImg, descr, coverContent, id } = data;
  const { title, experiment } = descr;

  return (
    <div className="journal-box">
      <Link to={`?id=${id}`}>
        <img src={`/img/${smallImg}`} alt="smallImg" />
      </Link>
      <div className="journal-box-info">
          <h2 className="j-title">{title}</h2>
        <p className="j-info">Experiment | {experiment}</p>
        <p className="j-list">{coverContent}</p>
      </div>
      <Link to={`?id=${id}`} className="viewmore">VIEW MORE</Link>
    </div>
  )
}

const List = (props) => {
  const { dataList, loading } = props;

  return (!loading && (
    <div id="itemList" className="box-container">
      <ul>
        {dataList.map((data) => (
          <ListItem key={data.id} data={data} />
        ))}
      </ul>
      <div className="clearfix"></div>
    </div>
  ))
}

const Detail = (props) => {
  const { activeData } = props;
  const { descr, contentImg, workId } = activeData;
  const title = descr.title.replace(/\n/g, '<br />');
  const content = descr.content.replace(/\n/g, '<br />');

  return(
    <div className="journal-content-boxcontainer">
      <div className="journal-content-info">
        <dl>
          <dt>Experiment</dt>
          <dd>{descr.experiment}</dd>
        </dl>
        <dl>
          <dt>Services</dt>
          <dd>{descr.services}</dd>
        </dl>
        <dl>
          <dt>Work</dt>
          <dd>
            <Link to={`/works?id=${workId}`}>{descr.work}</Link>
          </dd>
        </dl> 
      </div>
      <div className="clearfix"></div>
      <div className="journal-content">
        <h2 className="j-c-title" dangerouslySetInnerHTML={{  __html: title }} />
        <p className="l-list-2" dangerouslySetInnerHTML={{  __html: content }} />
        {contentImg.map((img) => (
          <img src={`/img/${img}`} alt="contentImg" />
        ))}
      </div>
    </div>
  )
}

const Journal = (props) => {
  const { dataList, getData, loading } = props;
  const activeData = useActiveData(dataList);

  useEffect(() => {
    const qs = {
      method: 'journal/sel',
      tag: 0,
    };

    getData(qs);
  }, []);

  return (activeData === null
    ? <List loading={loading} dataList={dataList} />
    : <Detail activeData={activeData} />);
};

export default Journal;
